<template>
  <div class="wrapper">
    <headBack>
      <template v-slot:title>
        <div class='title'>
          {{serveTit[type]}}
        </div>
      </template>
    </headBack>
    <div class='header_cont'></div>

    <van-notice-bar
        left-icon="volume-o"
        text="您已进入医护到家服务页面"
        mode="closeable"
        background="#C5D5FC"
        color="#ffffff"
    />
    <div class="list_box">
          <div class="list">
            <div class="cont"
                 v-for="(item,index) in serveList.target"
                 @click="toGoodsDetail(item)"
            >
              <div class="img_box">
                <img :src="item.pic" alt="">
              </div>
              <div class="serve_text">
                <div>
                  <div class="t1">{{item.serviceName}}</div>
                  <div class="t2">{{item.shortDiscrip}}</div>
                </div>
                <div class="price_t4">
                  <div class="left_p">
                    <span class="price_1">￥</span>
                    <span class="price_2">{{item.singlePrice}}</span>
                    <span class="price_3">/次</span>
                  </div>
                  <div class="btn_buy" v-if="item.subscribeNum">
                    <van-icon name="friends" size="15" color="#d1d1d1"/><span>{{item.subscribeNum}}人已购买</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

    </div>

    <!--        <div class="list">-->
    <!--            <div class="cont"-->
    <!--                 v-for="(item,index) in serveList.target"-->
    <!--                 @click="toGoodsDetail(item)"-->
    <!--            >-->
    <!--                <div class="img_box">-->
    <!--                    <img :src="item.pic" alt="">-->
    <!--                </div>-->
    <!--                <div class="serve_text">-->
    <!--                    <div class="t1">{{item.name}}</div>-->
    <!--                    <div class="t2">{{item.descrip}}</div>-->
    <!--                    <div class="price_t4">-->
    <!--                        <div>-->
    <!--                            <span class="price_1">￥</span>-->
    <!--                            <span class="price_2">{{item.singlePrice}}</span>-->
    <!--                            <span class="price_3">/次</span>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--                <div class="right">-->
    <!--                    <van-icon name="arrow" size="18" color="#666666"/>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
  </div>
</template>

<script>
import {useRouter, useRoute} from 'vue-router'
import {onMounted, ref, computed, reactive, toRefs} from "vue";
import {useStore} from 'vuex'
import homeApi from '@axios/home'
export default {
  name: "bloodList",
  setup() {

    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    const active = ref(0);
    const serveType = reactive([]);
    const serveList = reactive([]);
    const serveTit = reactive([
        '',
        '基础护理',
        '护士陪诊',
        '基因检测',
        '居家康复',
        '养生保健',
    ])
    const type = route.query.type
    const getList = (index) => {
      let formData = {
         type : type
      };
      homeApi.getHotServicesForTaiping(formData).then((res) => {
        console.log(res)
        serveList.target = res.data;
      })

    }
    getList()




    const toGoodsDetail = (item) => {
      if(item.serviceType == 'jiyin'){
        console.log(item.serviceType)
        router.push({
          name: 'genesDetail',
          query: {
            rolecode: item.roleCode,
            firsvcode: item.firSvCode,
            // scdsvcode: item.scdSvCode,
            serviceType: item.serviceType
          }
        })
        return;
      }

      if(item.serviceType == '052' || item.serviceType == '2' ){
        router.push({
          name: 'newestDetail',
          query: {
            rolecode: item.roleCode,
            firsvcode: item.firSvCode,
            serviceType: item.serviceType
          }
        })
        return;
      }

      if(item.roleCode == '019'){ // 陪诊陪护
        router.push({
          name: 'serviceDetail',
          query: {
            rolecode: item.roleCode,
            firsvcode: item.firSvCode,
            scdsvcode:item.scdSvCode
          }
        })
      }else{
        router.push({
          name: 'goodsDetail',
          query: {
            rolecode: item.roleCode,
            firsvcode: item.firSvCode,
            scdsvcode:item.scdSvCode,
            examCode : item.examCode ? item.examCode : ''
          }
        })
      }

    }

    return {
      active,
      type,
      serveTit,
      serveType,
      toGoodsDetail,
      serveList
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.wrapper{
  width: 100%;
  height:100%;

}
.list_box {
  width: 100%;
  min-height:100%;
  background:#F8F8F8;
  box-sizing: border-box;
  padding: 20px 20px 40px 20px;
  //background-image:linear-gradient(to bottom,#FEFEFE 0%,#F8F8F8 140px,#F2F2F2 50%);
  ::v-deep(.van-sticky){
    background:#F8F8F8;
  }
  ::v-deep(.van-tab){
    flex: none;
    margin-right: 10px;
  }


  ::v-deep(.van-tabs--line .van-tabs__wrap){
    .van-tabs__nav{
      background: none;
    }
    .van-tabs__line{
      width: 50px;
    }
    .van-tabs__nav--line{
      padding-bottom: 20px;
    }
    .van-tab--active{
      font-size: 29px;
    }

  }
  .tit {
    font-size: 30px;
    color: red;
  }

  .list {
    min-height: 600px;
    font-size: 24px;
    .cont {
      background: #FFFFFF;
      display: flex;
      box-sizing: border-box;
      padding: 20px;
      border-radius:20px;
      margin-bottom: 20px;
      //border-bottom: 1px solid #999999;
      .img_box {
        width: 200px;
        height: 200px;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 15px;
        }
      }

      .serve_text {
        box-sizing: border-box;
        width:450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .t1 {
          margin-top: 10px;
          font-size: 30px;
          font-weight: 500;
          color: #313131;
          line-height: 38px;
        }

        .t2 {
          margin: 10px 0 10px 0;
          font-size: 25px;
          font-weight: 400;
          color: #666666;
          //height:90px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .t3 {
          height: 60px;
          span {
            display: inline-block;
            padding: 0 10px;
            height: 40px;
            border: 1px solid #00c291;
            border-radius: 4px;
            color: #00c291;
            text-align: center;
            line-height: 40px;
            margin-right: 12px;

          }
        }

        .price_t4 {
          font-family: PingFangSC-Semibold, PingFang SC;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .left_p{
            margin-bottom: -3px;
          }
          .price_1 {
            color: #FF6161;
            font-size: 26px;
            font-weight: 600;
          }

          .price_2 {
            color: #FF6161;
            font-size: 38px;
            font-weight: 600;
          }

          .price_3 {
            color: #FF6161;
            font-size: 26px;
          }
          .btn_buy {
            font-size: 24px;
            color: #999999;
            margin-right: 10px;
            .van-icon{
              vertical-align: middle;
            }
            span{
              margin-left: 5px;
              vertical-align: middle;
            }
          }
        }
      }

    }
  }
}
</style>
